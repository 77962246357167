<!-- 首页 -->
<template>
  <div @click="closeOptionList">
    <header class="headerBg">
      <!-- <div>
        <img
          class="niuniulogo"
          src="../../assets/niuniulogo.png"
          alt=""
          srcset=""
        />
                    </div> -->
      <!-- <div>
        <img
          class="weixinLogo"
          src="../../assets/weixin.jpg"
          alt=""
          srcset=""
        />
                    </div> -->
      <div class="w350 ps-relative" style="text-align: center;" @click.stop>
        <div style="display: flex;
                  align-items: center;"> <span class="title">牛牛收卡</span>
          <div style="flex-grow: 1;">
            <van-search shape="round" :clearable="false" background="#c30000" v-model="seachGc_name"
              placeholder="我有二手卡要回收" @input="onInput" @focus="onFocus">
            </van-search>
          </div>
        </div>
        <div class="searchResult" v-if="showSearchOption">
          <p class="searchOption" v-for="item in searchOptionList" :key="item.gc_id" @click="jumpSellCard(item)">
            <span>{{ item.gc_name }}</span>
          </p>
        </div>
      </div>
      <div class="announcement" @click="toAnnouncement">
        <p v-for="item in AnnouncementList" :key="item.id">
          <van-icon name="volume-o" />
          <span class="announcementText">{{ item.art_title }}</span>
          <span>{{ item.art_addtime }}</span>
        </p>
      </div>
    </header>
    <main style="position:relative; background: #f3f3f3;">
      <div class="cardTypeList">
        <p v-for="item in cardTypeList" :key="item.id" @click="jumpSellCard(item)">
          <img :src="item.gt_cover" alt="" srcset="" />
          <span>{{ item.gt_name }}</span>
        </p>
      </div>
      <div class="clearFloat"></div>
      <!-- <div class="messageSwipe">
        <van-swipe
          style="height: 40px; line-height:40px ;  background: #e92d2c;
"
          autoplay="700"
          vertical
          :show-indicators="false"
        >
          <van-swipe-item>1</van-swipe-item>
          <van-swipe-item>wqeqweqwe</van-swipe-item>
          <van-swipe-item>bbbbbbbbbbbbb</van-swipe-item>
        </van-swipe>
                    </div> -->
      <van-notice-bar left-icon="volume-o" :scrollable="false" background="#c30000" style="color: #fff;">
        <van-swipe vertical class="notice-swipe" :autoplay="2000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in scrollList" :key="index"><span style="color:#fff">用户&nbsp{{
            item.u_ali_realname }} &nbsp 于{{ item.pr_addtime }}&nbsp提现 &nbsp
              {{ item.pr_price }}元</span></van-swipe-item>
        </van-swipe>
      </van-notice-bar>

      <div class="usedCardTitle">
        <span> 热门二手礼品卡回收</span>
        <span class="show" @click="jumpSellCard()">查看更多>></span>
      </div>
      <div class="usedCardList">
        <p v-for="item in hostCardList" :key="item.gc_id" @click="jumpSellCard(item)">
          <img :src="item.gc_cover" alt="" srcset="" />
          <span style="font-size: 13px; color: #505050;">{{ item.partner_discount ? item.partner_discount
            : item.high_cost_discount }}折回收</span>
          <span>{{ item.gc_name }}</span>
        </p>
      </div>

      <div class="jumpSellcard" @click="jumpSellCard()">
        查看更多礼品卡在线回收
      </div>
    </main>
    <!-- <suspension></suspension> -->
  </div>
</template>

<script>
import debounceAndThrottle from "@/tools/debounceAndThrottle";

// import suspension from "../../components/suspension.vue";
export default {
  data() {
    return {
      showSearchOption: false,
      seachGc_name: "",
      searchOptionList: [],
      cardTypeList: [
        {
          value: 3,
          text: "电商礼品卡",
        },
        {
          value: 4,
          text: "旅游卡",
        },
        {
          value: 1,
          text: "油卡",
        },
        {
          value: 2,
          text: "话费卡",
        },

        {
          value: 5,
          text: "商超百货卡",
        },
        {
          value: 6,
          text: "餐饮服务",
        },
      ],
      hostCardList: [],
      AnnouncementList: [],
      scrollList: [],
    };
  },

  components: {
    // suspension,
  },
  created() {
    this.isWX =
      navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    this.getCardTypeList();
    this.getHostCardList();
    this.getAnnouncementList();
    this.getscroll();
  },

  mounted() { },

  methods: {
    toAnnouncement() {
      this.$router.push("/announcement");
    },
    closeOptionList() {
      this.showSearchOption = false;
    },
    onFocus() {
      this.showSearchOption = true;
    },

    onInput() {
      console.log("搜索");
      debounceAndThrottle.throttle(() => {
        this.$axios
          .get("/api/shop/getGoodsClassList", {
            params: {
              key: this.seachGc_name,
              gc_type: 0,
            },
          })
          .then((res) => {
            if (res.code == "000") {
              this.searchOptionList = res.data;
            }
          });
      }, 300);
    },
    jumpSellCard(card) {
      this.$router.push({
        name: "home",
        params: card,
      });
    },
    getCardTypeList() {
      this.$axios.get("/api/shop/getGoodsTypeList").then((res) => {
        if (res.code == "000") {
          this.cardTypeList = res.data;
        }
      });
    },
    getHostCardList() {
      this.$axios.get("/api/shop/getHotGoodsClass", {
        params: {
          partner_id: window.localStorage.getItem("partner_id")
            ? window.localStorage.getItem("partner_id")
            : 0,
        }
      }).then((res) => {
        console.log(res);
        if (res.code == "000") {
          this.hostCardList = res.data;
        } else {
        }
      });
    },
    getAnnouncementList() {
      this.$axios
        .get("/api/Web/getArticleList", {
          params: {
            arc_code: "notice",
          },
        })
        .then((res) => {
          if (res.code == "000") {
            this.AnnouncementList = res.data.rows.slice(0, 1);
          }
        });
    },

    getscroll() {
      this.$axios.post("/api/shop/getRandPaymentRecord").then((res) => {
        if (res.code == "000") {
          this.scrollList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 100px) {
  .headerBg {
    background-image: url("../../assets/homeBG.png");
    margin-top: 40px;
    box-shadow: 0px 6px 6px 2px #c30000;
  }
}

@media screen and (max-width: 1000px) {
  .headerBg {
    background: #c30000;
    margin-top: 40px;
    box-shadow: 0px 6px 6px 2px #c30000;
  }
}

header {
  height: 200px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .niuniulogo {
    margin: 10px 0 0px 0;
    width: 50px;
  }

  .weixinLogo {
    width: 70px;
  }

  .onSearch {
    font-size: 16px;
    color: rgb(247, 245, 245);
    z-index: 999;
    background: #e92d2c;
    padding: 0 0 0 3px;
  }

  .title {
    display: inline-block;
    color: #fff;
    font-size: 19px;
    width: 100px;
  }

  .searchResult {
    width: 241px;
    height: 200px;
    left: 97px;
    border-radius: 5px;
    background: #ffffff;
    position: absolute;
    overflow-y: auto;
    z-index: 99999;
    border: 1px solid #eee;
    box-shadow: 1px 1px 1px #eee;
  }

  .searchOption {
    margin: 4px;
    padding: 3px;
    border-bottom: 1px solid #fff;
    // height: 23px;
    font-size: 20px;
    word-break: break-word;
  }

  .announcement {
    color: #fff;
    margin: 10px 10px 10px 10px;
    font-size: 16px;
    font-family: inherit;

    p {
      height: 28px;
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 28px;
      margin: 0px;
      padding: 0px 6px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 18px;
      background: #ffffff4d;
    }

    .announcementText {
      display: inline-block;
      width: 200px;
      margin: 0 15px 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.clearFloat {
  height: 160px;
}

.cardTypeList {
  border-radius: 5px;
  display: flex;
  flex-flow: wrap;
  display: flex;
  flex-flow: wrap;
  position: absolute;
  background: #fff;
  top: -86px;
  width: 90%;
  left: 20px;

  p {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    margin: 10px 0px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

// .messageSwipe {
//   background: #f5f5f5;
//   color: #fff;
//   font-size: 14px;
//   margin: 10px 0px 0 0px;
//   padding: 15px;
// }
.usedCardTitle {
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  margin: 25px 10px 30px 10px;

  .show {
    font-size: 15px;
    color: #999999;
  }

  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
  }
}

.usedCardList {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 20px;

  p {
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 7px 14px 7px;
    font-size: 16px;
    border-radius: 9px;
    background: #fff;
    padding: 10px;

    img {
      width: 60px;
      height: 43px;
    }
  }
}

.jumpSellcard {
  color: #e92d2c;
  height: 90px;
  text-align: center;
  line-height: 90px;
  // background: #fff;
  font-size: 20px;
  border-top: 1px solid #f5f5f5;
}

.w350 {
  width: 350px;
}

.ps-relative {
  position: relative;
}

.van-search__action {
  padding: 0px;
}

.bg-f9 {
  // background: #f9f9f9;
}
</style>
